import React from "react";

/* Bootstrap Components */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";


/* Others */
import cajavacia from "src/assets/img/cajavacia.png";

function NoEncontradoVerificacion() {

    return (
      <Container>
        <Row>
          <Col className="text-center">
            <img
              src={cajavacia}
              className="img-fluid mx-auto d-block mb-4 mt-4"
              alt="not-found"
            />
            <h4 style={{ color: "#354052" }}>
              No hay registros guardados.
              <br />
            </h4>
          </Col>
        </Row>
      </Container>
    );
  }

export default NoEncontradoVerificacion;
