import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
/* Redux */
import { connect } from "react-redux";
import {
  getDesgrabador,
  getTextosDesgrabador,
  resetDesgrabador,
  getDesgrabadorFile,
  getDesgrabadorHistorial,
  getDesgrabadorHistorialID,
  getDesgrabadorHistorialFichero,
  getDesgrabadorHistorialFicheroID,
  clearLabelDesgrabador,
  getCleanErrorDesgrabador,
  persistLegend,
} from "src/store";

/* Estilo */
import { StyledBackground } from "../styled";

/* Bootsrap y MD */
import {
  MdContentCopy,
  MdCheckCircleOutline,
  MdOutlineHighlightOff,
} from "react-icons/md";
import { Row, Col, Form } from "react-bootstrap";
import { IoIosRefresh } from "react-icons/io";

/* Components*/
import Logo from "src/components/Desgrabador/Logo";
import LoadingCard from "src/components/LoadingCard";

/* Assets */
import not from "src/assets/img/not.png";
import error from "src/assets/img/error.png";
import { GiSandsOfTime } from "react-icons/gi";

function HomeDesgrabador(props) {
  const [fileName, setFileName] = useState("Subí un archivo (Máx. 100MB)");
  const [desgrabacion, setDesgrabacion] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [ytUrl, setYtUrl] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("es");

  const [isDesgrabacionYoutubeExitosa, setIsDesgrabacionYoutubeExitosa] =
    useState(false);
  const [isDesgrabacionYoutubeError, setIsDesgrabacionYoutubeError] =
    useState(false);
  const [isProcessingYoutube, setIsProcessingYoutube] = useState(false);

  const [isDesgrabacionFicheroExitosa, setIsDesgrabacionFicheroExitosa] =
    useState(false);
  const [isDesgrabacionFicheroError, setIsDesgrabacionFicheroError] =
    useState(false);
  const [isProcessingFichero, setIsProcessingFichero] = useState(false);

  const [showErrorAboveHistory, setShowErrorAboveHistory] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [localHistorialItems, setLocalHistorialItems] = useState(
    props.desgrabador_historial_item || []
  );
  const intervalIdRef = useRef(null);
  const [isHasPendingFile, setisHasPendingFile] = useState(false);


  const [show, setShow] = useState(false);
  const [inputKey, setInputKey] = useState(0); // Para forzar el re-render

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    props.getDesgrabadorHistorial();
  }, []);

   useEffect(() => {
    let intervalId;
    if (isHasPendingFile) {
      intervalId = setInterval(() => {
        if (isHasPendingFile) {
          // ACA DE NUEVO LLAMAMOS A LA API Y HACEMOS EL Try Catch
          checkStatus();
        }
      }, 30000); // 10 seconds
    }
    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isHasPendingFile]);  // Re-run effect if hasPending changes

  useEffect(() => {
    if (props.desgrabador_historial_item !== localHistorialItems) {
      setLocalHistorialItems(props.desgrabador_historial_item);
    }
  }, [props.desgrabador_historial_item]);


  useEffect(() => {
    if (
      props.desgrabador &&
      props.desgrabador.subtitles &&
      props.desgrabador.subtitles.length > 0
    ) {
      console.log("Transcripción exitosa (useEffect):", props.desgrabador);
      //  setLoading(true);
      //setIsProcessingYoutube(false);
      setIsDesgrabacionYoutubeExitosa(true);
    }
  }, [props.desgrabador]);

   useEffect(() => {
    if (
      props.desgrabador_historial_fichero &&
      props.desgrabador_historial_fichero.length > 0
    ) {
      checkDesgrabacionStatusFichero();
      setIsProcessingFichero(false);
      setIsDesgrabacionFicheroExitosa(true);
    }
  }, [props.desgrabador_historial_fichero]);

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    console.log("Archivo seleccionado");
    const newFile = e.target.files[0];
    console.log("Nuevo archivo:", newFile);

    if (newFile) {
      // Manejo de archivos grandes
      if (newFile.size > 100 * 1024 * 1024) {
        setFile(newFile);
        setShow(true); // Muestra el modal
      } else {
        // Solo guardar el archivo y su nombre
        setFile(newFile);
        setFileName(newFile.name); // Guarda el nombre del archivo
      }

      // Reiniciar el input de archivo
      setInputKey((prevKey) => prevKey + 1);
    }
  };

  const truncateFileName = (fileName, maxLength) => {
    if (!fileName) return "";

    if (fileName.length <= maxLength) {
      return fileName;
    }
    return fileName.substring(0, maxLength) + "...";
  };

  const handleButtonClick = () => {
    document.getElementById("file-upload").click();
    setYtUrl("");
    props.getCleanErrorDesgrabador();
  };


  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsDesgrabacionFicheroExitosa(false);
    setIsDesgrabacionFicheroError(false);
    setIsProcessingFichero(false);
    setIsProcessingYoutube(false);

    try {
      // Promesa para manejar la subida del archivo
      const fileUploadPromise =
        fileName && file
          ? (async () => {
              props.persistLegend(true); // Mostrar mensaje de subida
              await props.getDesgrabadorFile(file);
              setIsProcessingFichero(true);
              setFileName("Subí un archivo (Máx. 100MB");
              setFile(null);
              props.persistLegend(false); // Ocultar mensaje de subida
            })()
          : Promise.resolve();

      // Promesa para manejar la desgrabación de la URL de YouTube
      const ytUrlPromise = ytUrl
        ? props.getDesgrabador(ytUrl, selectedLanguage).then(() => {
            // setYtUrl("");
          })
        : Promise.resolve();

      // Espera a que ambas promesas se resuelvan
      await Promise.all([fileUploadPromise, ytUrlPromise]);
    } catch (error) {
      console.log("Error al desgrabar", error);
      if (fileName && file) {
        setIsDesgrabacionFicheroError(true);
        setIsProcessingFichero(false);
      }
      if (ytUrl) {
        setIsDesgrabacionYoutubeError(true);
        setIsProcessingYoutube(false);
      }
      setShowErrorAboveHistory(true); // Mostrar el error arriba del historial
      setIsUploadingFile(false); // Asegurarse de ocultar el mensaje en caso de error
    }
  };

  const checkStatus = async () => {
    try {
      await props.getDesgrabadorHistorialFichero();
      // checkDesgrabacionStatusFichero();
    } catch (error) {
      console.log("Error al chequear el estado de desgrabación", error);

      setIsDesgrabacionFicheroError(true);
      setIsProcessingFichero(false);
      //  setLoading(false);
    }
  };


  const checkDesgrabacionStatusFichero = () => {
    const { desgrabador_historial_fichero } = props;

    const allReady = desgrabador_historial_fichero.every(
      (item) => item.status === "ready"
    );

    const hasPending = desgrabador_historial_fichero.some(
      (item) => item.status === "pending"
    );

    if (allReady && !hasPending) {
      console.log("Todos los archivos están listos.");

      setIsProcessingFichero(false);
      setIsDesgrabacionFicheroExitosa(true);
      setisHasPendingFile(false);
      // Otros cambios de estado que necesites hacer
    } else {
      console.log("Aún hay archivos pendientes.");
      setisHasPendingFile(true);
    }
  };

  const checkDesgrabacionStatus = () => {
    const data = props.desgrabador;
    if (data && data.subtitles && data.subtitles.length > 0) {
      console.log("Transcripción exitosa:", data);
      setIsProcessingYoutube(false);
      setIsDesgrabacionYoutubeExitosa(true);
    } else {
      setTimeout(checkDesgrabacionStatus, 10000);
    }
  };

  const handleCardClick = (id) => {
    try {
      props.resetDesgrabador();
      props.getCleanErrorDesgrabador();
       setSelectedLanguage("es");

      const lang = "es";
      setSelectedCardId(id);
      props.getDesgrabadorHistorialID(id, lang);
    } catch (error) {
      console.log("se produjo un error");
    }
  };



  const cleanInput = () => {
    props.resetDesgrabador();
    props.clearLabelDesgrabador();
    setYtUrl("");
    setDesgrabacion(false);
    setFile(null);
    setFileName("Subi un archivo");
    props.getCleanErrorDesgrabador();
  };

const handleLanguageChange = (e) => {
  const newLanguage = e.target.value;
  setSelectedLanguage(newLanguage);


  props.clearLabelDesgrabador();

  if (selectedCardId) {
    // Al abrir una tarjeta, asegúrate de que el idioma por defecto sea "en"
    props.getDesgrabadorHistorialID(selectedCardId, newLanguage);
  } else {
    props.getDesgrabador(ytUrl, newLanguage);
    const subtitles = props.desgrabador?.subtitles?.[newLanguage];

    // Solo ejecuta getTextosDesgrabador si el idioma es "es"
    if (newLanguage === "es" && subtitles && subtitles.length > 0) {
      console.log("Subtítulos en español:", newLanguage);
      const textArray = subtitles.map((el) => el.text);
      props.getTextosDesgrabador(textArray);
    }
  }

  // Verifica el estado de label_desgrabador
  console.log("label_desgrabador:", props.label_desgrabador);
};



  const handleCopyClickFicheroYt = () => {
    const textToCopy = props.desgrabador_historial_item
      ?.map((el) => el.text)
      .join(" ");

    console.log("textToCopy", textToCopy);

    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopySuccess("¡Texto copiado!");
      alert("¡Texto copiado al portapapeles!");
    } catch (err) {
      console.error("Error al copiar el texto: ", err);
      setCopySuccess("Error al copiar el texto. Intenta manualmente.");
    }

    setTimeout(() => {
      document.body.removeChild(textArea);
      setCopySuccess(null);
    }, 3000);
  };

  const handleCopyClickFichero = () => {
    const textToCopy = props.desgrabador_historial_fichero_item
      ?.map((el) => el.text)
      .join(" ");

    console.log("textToCopy", props.desgrabador_historial_item);

    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopySuccess("¡Texto copiado!");
      alert("¡Texto copiado al portapapeles!");
    } catch (err) {
      console.error("Error al copiar el texto: ", err);
      setCopySuccess("Error al copiar el texto. Intenta manualmente.");
    }

    setTimeout(() => {
      document.body.removeChild(textArea);
      setCopySuccess(null);
    }, 3000);
  };

  const handleCopyClickVideoUrl = () => {
    const textToCopy = props.desgrabador?.subtitles
      ?.map((el) => el.text)
      .join(" ");

    console.log("textToCopy", props.desgrabador_historial_item);

    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopySuccess("¡Texto copiado!");
      alert("¡Texto copiado al portapapeles!");
    } catch (err) {
      console.error("Error al copiar el texto: ", err);
      setCopySuccess("Error al copiar el texto. Intenta manualmente.");
    }

    setTimeout(() => {
      document.body.removeChild(textArea);
      setCopySuccess(null);
    }, 3000);
  };

  const subtitles_api = {
    es: [props.desgrabador?.subtitles?.es],
    en: [props.desgrabador?.subtitles?.en],
    pt: [props.desgrabador?.subtitles?.pt],
  };

  const subtitleKeysWithElements = Object.keys(subtitles_api).filter(
    (key) => subtitles_api[key].length > 0
  );

  function getLanguageName(key) {
    switch (key) {
      case "es":
        return "Español";
      case "en":
        return "Inglés";
      case "pt":
        return "Portugués";
      default:
        return "Desconocido";
    }
  }

  const handleCardClickFichero = (id) => {
    try {
      const lang = "es";
      props.resetDesgrabador(); // Limpia el estado previo
      props.getCleanErrorDesgrabador(); // Limpia errores previos
      props.getDesgrabadorHistorialFicheroID(id, lang); // Obtiene el nuevo historial
      setSelectedCardId(id);
    } catch (error) {
      console.log("Se produjo un error", error);
    }
  };

  return (
    <StyledBackground fluid={true}>
      <Row>
        <Col lg={12}>
          <div className="titulo">
            <Logo />
            <p>Desgrabador</p>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft: "50px", marginTop: "40px" }}>
        <Col lg={8}>
          <div id="title">
            <div>
              <h1
                className="mb-3"
                style={{ fontSize: "16px", color: "#354052" }}
              >
                ¿Qué querés desgrabar?
              </h1>
              <div className="row">
                <div className="col-md-6">
                  <form onSubmit={handleOnSubmit} className="form">
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ marginBottom: "10px" }}
                      >
                        <input
                          type="text"
                          name="youtube_url"
                          id="youtube-url-field"
                          className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                          placeholder="Ingresá la URL del video"
                          value={ytUrl}
                          onChange={(e) => {
                            setYtUrl(e.target.value);
                            props.getCleanErrorDesgrabador();
                          }}
                        />
                      </div>
                      <div className="col-lg-12 mt-2">
                        {props.isUploadingFile ? (
                          <button
                            type="button"
                            style={{
                              border: "1px solid #000",
                              borderRadius: "20px",
                              color: "#000",
                              background: "transparent",
                              fontWeight: "700",
                              fontSize: "12px",
                              lineHeight: "24px",
                              padding: "0px 18px",
                            }}
                            disabled
                          >
                            Desgrabar
                          </button>
                        ) : (
                          <button id="analizar" type="submit" className="enter">
                            Desgrabar
                          </button>
                        )}
                        <button
                          id="analizar"
                          type="button"
                          className="borrar"
                          onClick={cleanInput}
                          style={{ borderRadius: "20px" }}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* {loading && !props.desgrabador_error ? <LoadingCard /> : null} */}
                </div>
                <div className="col-md-6">
                  <div className="file-upload-container">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="file-upload"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      key={file ? file.name : "file-input"} // Forzar re-render
                    />
                    <label
                      htmlFor="file-upload"
                      className="file-label"
                      style={{
                        marginBottom: "0",
                        marginRight: "25px",
                        color: "#C4C4C4",
                      }}
                    >
                      {truncateFileName(fileName, 31)}
                    </label>
                    <button
                      className="btn-reset"
                      style={{ padding: "0 18px" }}
                      onClick={handleButtonClick}
                    >
                      Subir archivo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <Row>
                <Col
                  lg={12}
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  {props.desgrabador &&
                  props.desgrabador.subtitles &&
                  props.desgrabador.subtitles.length > 0 ? (
                    <div
                      className="col-lg-12"
                      style={{
                        marginTop: "15px",
                        background: "#fff",
                        padding: "35px",
                        borderRadius: "10px",
                      }}
                    >
                      {subtitleKeysWithElements.length > 0 ? (
                        <div
                          className="transcripcion"
                          style={{ display: "flex" }}
                        >
                          <div style={{ textAlign: "initial" }}>
                            <p style={{ color: "#000" }}>
                              <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_buscar.svg" />{" "}
                              Usá Ctrl+F para buscar en la transcripción.
                            </p>
                            <p style={{ color: "#000" }}>
                              <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_seleccion.svg" />
                              Si hacés click en las frases verás el momento del
                              video donde se dijeron.
                            </p>
                          </div>
                          <div>
                            <button
                              className="btn-copy"
                              onClick={handleCopyClickVideoUrl}
                            >
                              <MdContentCopy style={{ marginRight: "5px" }} />
                              <b>Copiar transcripción</b>
                            </button>
                            <Form.Select
                              className="options"
                              onChange={handleLanguageChange}
                              style={{
                                fontSize: "12px",
                                borderRadius: "20px",
                              }}
                              placeholder="Opciones disponibles"
                            >
                              {subtitleKeysWithElements.map((key) => (
                                <option key={key} value={key}>
                                  {getLanguageName(key)}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      ) : null}

                      {props.desgrabador?.subtitles?.length > 0 ? (
                        <h5 className="text-justify py-5 texto">
                          {props.desgrabador.subtitles.map((el, index) => {
                            const labelInfo = props.label[index];
                            const isCurrentLanguage = selectedLanguage === "es";
                            const shouldApplyGreenClass =
                              labelInfo && labelInfo.label && isCurrentLanguage;
                            return (
                              <a
                                key={index}
                                href={`https://youtube.com/watch?v=${props.desgrabador.youtube_id}&t=${el.timestamp_seconds}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-toggle="tooltip"
                                title="Hacé click para ver esta frase en el video 111"
                                className={
                                  shouldApplyGreenClass ? "green" : "frase_link"
                                }
                              >
                                {el.text + " "}
                              </a>
                            );
                          })}
                        </h5>
                      ) : null}
                      <div style={{ textAlign: "center" }}>
                        <button
                          className="btn-reset"
                          type="button"
                          onClick={cleanInput}
                        >
                          <IoIosRefresh />
                          <b>Realizar otra desgrabación</b>
                        </button>
                      </div>
                    </div>
                  ) : props?.desgrabador_historial_item &&
                    props?.desgrabador_historial_item?.length > 0 ? (
                    <div
                      className="col-lg-12"
                      style={{
                        marginTop: "15px",
                        background: "#fff",
                        padding: "35px",
                        borderRadius: "10px",
                      }}
                    >
                      {" "}
                      <div
                        className="transcripcion"
                        style={{ display: "flex" }}
                      >
                        <div style={{ textAlign: "initial" }}>
                          <p style={{ color: "#000" }}>
                            <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_buscar.svg" />{" "}
                            Usá Ctrl+F para buscar en la transcripción.
                          </p>
                          <p style={{ color: "#000" }}>
                            <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_seleccion.svg" />
                            Si hacés click en las frases verás el momento del
                            video donde se dijeron.
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn-copy"
                            onClick={handleCopyClickFicheroYt}
                          >
                            <MdContentCopy style={{ marginRight: "5px" }} />
                            <b>Copiar transcripción</b>
                          </button>
                          <Form.Select
                            className="options"
                            onChange={handleLanguageChange}
                            value={selectedLanguage} // Asegúrate de que el valor por defecto sea el correcto
                            style={{
                              fontSize: "12px",
                              borderRadius: "20px",
                            }}
                            placeholder="Opciones disponibles"
                          >
                            {subtitleKeysWithElements.map((key) => (
                              <option key={key} value={key}>
                                {getLanguageName(key)}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                      <h5 className="text-justify py-5 texto">
                        {props?.label_desgrabador.length > 0
                          ? props?.label_desgrabador.map((el, index) => {
                              const isCurrentLanguage = selectedLanguage === "es";
                              const shouldApplyGreenClass = el.label && isCurrentLanguage;
                              console.log(
                                "selectedLanguage***",
                                selectedLanguage
                              );

                                console.log(
                                  "shouldApplyGreenClass***",
                                  shouldApplyGreenClass
                                );

                                console.log(
                                  "isCurrentLanguage***",
                                  isCurrentLanguage
                                );

                              const youtubeId =
                                props.desgrabador_historial[index]?.youtube_id;
                              const timestamp =
                                props.desgrabador_historial_item[index]
                                  ?.timestamp_seconds;

                              return (
                                <a
                                  key={index}
                                  target="_blank"
                                  href={`https://youtube.com/watch?v=${youtubeId}&t=${timestamp}`}
                                  rel="noopener noreferrer"
                                  data-toggle="tooltip"
                                  title="Hacé click para ver esta frase en el video"
                                  className={
                                    shouldApplyGreenClass
                                      ? "green"
                                      : "frase_link"
                                  }
                                >
                                  {el.sentence + " "}
                                </a>
                              );
                            })
                          : props?.desgrabador_historial_item?.length > 0
                          ? props?.desgrabador_historial_item?.map(
                              (el, index) => {
                                // Obtener el youtube_id correspondiente
                                const youtubeId =
                                  props.desgrabador_historial[index]
                                    ?.youtube_id;
                                const timestamp = el.timestamp_seconds;

                                return (
                                  <a
                                    key={el.id}
                                    target="_blank"
                                    href={`https://youtube.com/watch?v=${youtubeId}&t=${timestamp}`}
                                    rel="noopener noreferrer"
                                    data-toggle="tooltip"
                                    title="Hacé click para ver esta frase en el video"
                                    className="frase_link"
                                  >
                                    {el.text + " "}
                                  </a>
                                );
                              }
                            )
                          : null}
                      </h5>
                      <div style={{ textAlign: "center" }}>
                        <button
                          className="btn-reset"
                          type="button"
                          onClick={cleanInput}
                        >
                          <IoIosRefresh />
                          <b>Realizar otra desgrabación</b>
                        </button>
                      </div>
                    </div>
                  ) : props.desgrabador_historial_fichero_item.length > 0 ? (
                    <div
                      className="col-lg-12"
                      style={{
                        marginTop: "15px",
                        background: "#fff",
                        padding: "35px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        className="transcripcion"
                        style={{ display: "flex" }}
                      >
                        <div style={{ textAlign: "initial" }}>
                          <p style={{ color: "#000" }}>
                            <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_buscar.svg" />{" "}
                            Usá Ctrl+F para buscar en la transcripción.
                          </p>
                          <p style={{ color: "#000" }}>
                            <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_seleccion.svg" />
                            Si hacés click en las frases verás el momento del
                            video donde se dijeron.
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn-copy"
                            onClick={handleCopyClickFichero}
                          >
                            <MdContentCopy style={{ marginRight: "5px" }} />
                            <b>Copiar transcripción</b>
                          </button>
                        </div>
                      </div>

                      {props.label_desgrabador.length > 0 ? (
                        <p
                          className="frase_link"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "22px",
                            color: "#000",
                          }}
                        >
                          {props.label_desgrabador.map((el, index) => {
                            const isCurrentLanguage = "es";
                            return (
                              <span
                                key={index}
                                className={
                                  isCurrentLanguage && el.label
                                    ? "green"
                                    : "frase_link"
                                }
                              >
                                {el.sentence + " "}
                              </span>
                            );
                          })}
                        </p>
                      ) : (
                        <p
                          className="frase_link"
                          style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "22px",
                            color: "#000",
                          }}
                        >
                          {props.desgrabador_historial_fichero_item.map(
                            (el, index) => (
                              <span key={index} className="frase_link">
                                {el.text + " "}
                              </span>
                            )
                          )}
                        </p>
                      )}

                      <div style={{ textAlign: "center" }}>
                        <button
                          className="btn-reset"
                          type="button"
                          onClick={cleanInput}
                        >
                          <IoIosRefresh />
                          <b>Realizar otra desgrabación</b>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={4} style={{ padding: "0" }}>
          {props.desgrabador_error && !isDesgrabacionYoutubeError && (
            <div className="container py-3 px-0">
              <div
                className="alert text-left"
                style={{ background: "#F72D54" }}
              >
                <div>
                  <p className="font-weight-bold" style={{ color: "white" }}>
                    <img
                      src={error}
                      style={{
                        width: "25px",
                        marginRight: "10px",
                      }}
                      alt="Error"
                    />
                    ¿No te funcionó?
                  </p>
                  <p style={{ marginLeft: "34px", color: "white" }}>
                    <b>&#8226;</b> Revisá que la dirección que estás ingresando
                    sea de un video de YouTube y contenga la ID del video.
                    <br />
                    <b>&#8226;</b> Revisá que el video que estas intentando
                    transcribir tenga subtítulos disponibles, ya sea
                    tradicionales o generados automáticamente, aunque sean en
                    otro idioma.
                    <b>&#8226;</b> Revisá que el video no sea "Privado", sino
                    "Público" u "Oculto".
                    <br />
                    <br />
                    Si todo lo de arriba está bien entonces es posible que el
                    Desgrabador esté con demasiadas tareas y el vínculo con
                    YouTube este saturado. Volvé a intentarlo en un par de
                    horas, perdón.
                  </p>
                </div>
              </div>
            </div>
          )}
          <Col lg={12}>
            {props.isUploadingFile && (
              <div
                style={{
                  marginTop: "30px",
                  background: "#354052",
                  padding: "10px",
                  borderRadius: "10px",
                  color: "#fff",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid 354052",
                  fontWeight: "bold",
                }}
              >
                <GiSandsOfTime
                  style={{
                    fontSize: "24px",
                    marginRight: "10px",
                    color: "#fff",
                  }}
                />
                Subiendo archivo. Cuando se cargue verás tu petición en el
                historial de archivos.
              </div>
            )}
            <div
              style={{
                maxHeight: "322px",
                overflowY: "auto",
                paddingRight: "10px",
                marginTop: "35px",
              }}
            >
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                Videos de Youtube
              </p>
              {props.desgrabador_historial &&
              props.desgrabador_historial.length > 0 ? (
                props.desgrabador_historial.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      marginTop: "10px",
                      background: "#fff",
                      padding: "10px",
                      borderRadius: "10px",
                      color: "#000",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border:
                        item.status === "ready"
                          ? "1px solid green"
                          : item.status === "pending" ||
                            item.status === "not_started"
                          ? "1px solid grey"
                          : "1px solid red",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(item.id)}
                  >
                    <div style={{ flex: 1 }}>
                      <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {item.video_title || item.url}
                      </div>
                      <div style={{ fontSize: "10px" }}>
                        {item.status === "pending"
                          ? "El procesamiento finalizará en unos minutos..."
                          : item.status === "error"
                          ? "Hubo un problema en el procesamiento..."
                          : ""}
                      </div>
                    </div>
                    <div
                      style={{
                        flexShrink: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.status === "ready" ? (
                        <MdCheckCircleOutline
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            color: "green",
                          }}
                        />
                      ) : item.status === "pending" ||
                        item.status === "not_started" ? (
                        <GiSandsOfTime
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        />
                      ) : (
                        <MdOutlineHighlightOff
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    marginTop: "10px",
                    background: "#fff",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#000",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  No posee historial
                </div>
              )}
            </div>

            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              Archivos
            </p>

            <div
              style={{
                maxHeight: "322px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              {props?.desgrabador_historial_fichero &&
              props?.desgrabador_historial_fichero?.length > 0 ? (
                props.desgrabador_historial_fichero?.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      marginTop: "10px",
                      background: "#fff",
                      padding: "10px",
                      borderRadius: "10px",
                      color: "#000",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border:
                        item.status === "ready"
                          ? "1px solid green"
                          : item.status === "pending" ||
                            item.status === "not_started"
                          ? "1px solid grey"
                          : "1px solid red",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClickFichero(item.id)}
                  >
                    <div style={{ flex: 1 }}>
                      <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {item.video_title || item.url}
                      </div>
                      <div style={{ fontSize: "10px" }}>
                        {item.status === "pending"
                          ? "El procesamiento finalizará en unos minutos..."
                          : item.status === "error"
                          ? "Hubo un problema en el procesamiento..."
                          : ""}
                      </div>
                    </div>
                    <div
                      style={{
                        flexShrink: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.status === "ready" ? (
                        <MdCheckCircleOutline
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            color: "green",
                          }}
                        />
                      ) : item.status === "pending" ||
                        item.status === "not_started" ? (
                        <GiSandsOfTime
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            color: "black",
                          }}
                        />
                      ) : (
                        <MdOutlineHighlightOff
                          style={{
                            fontSize: "24px",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    marginTop: "10px",
                    background: "#fff",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#000",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  No posee historial
                </div>
              )}
            </div>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Archivo demasiado grande</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                El archivo que intentaste subir supera el límite de 100MB. Por
                favor, selecciona un archivo más pequeño.
              </Modal.Body>
              <Modal.Footer>
                <button
                  variant="secondary"
                  className="enter"
                  onClick={() => setShow(false)}
                >
                  Cerrar
                </button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Col>
      </Row>
    </StyledBackground>
  );
}

const mapStateToProps = (state) => {
  return {
    desgrabador: state.desgrabador,
    error: state.error,
    label: state.label,
    desgrabador_historial: state.desgrabador_historial,
    error_desgrabador: state.error_desgrabador,
    desgrabador_historial_item: state.desgrabador_historial_item,
    label_desgrabador: state.label_desgrabador,
    desgrabador_historial_fichero: state.desgrabador_historial_fichero,
    desgrabador_historial_fichero_item:
      state.desgrabador_historial_fichero_item,
    desgrabador_flag: state.desgrabador_flag,
    desgrabador_error: state.desgrabador_error,
    isUploadingFile: state.isUploadingFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDesgrabador: (video_url, lang) =>
      dispatch(getDesgrabador(video_url, lang)),
    getTextosDesgrabador: (sentences) =>
      dispatch(getTextosDesgrabador(sentences)),
    getDesgrabadorFile: (file) => dispatch(getDesgrabadorFile(file)),
    getDesgrabadorHistorial: () => dispatch(getDesgrabadorHistorial()),
    getDesgrabadorHistorialFichero: () =>
      dispatch(getDesgrabadorHistorialFichero()),

    resetDesgrabador: () => dispatch(resetDesgrabador()),
    getDesgrabadorHistorialID: (id, lang) =>
      dispatch(getDesgrabadorHistorialID(id, lang)),
    getDesgrabadorHistorialFicheroID: (id, lang) =>
      dispatch(getDesgrabadorHistorialFicheroID(id, lang)),
    clearLabelDesgrabador: () => dispatch(clearLabelDesgrabador()),
    getCleanErrorDesgrabador: () => dispatch(getCleanErrorDesgrabador()),
    persistLegend: (value) => dispatch(persistLegend(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDesgrabador);
