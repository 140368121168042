import axios from "axios";
import fromatterJson, { getStatusLabel } from "./formatter";
import mockmenuUsuarios from "./mockmenuUsuarios.json";

//const BASE_URL = "https://chequeabot.com/gateway/dev";
const BASE_DEV = "https://dev.chequeado.com"
const BASE_PREDEV = "https://predev.chequeado.com";

const api = {
  fetchDataCardsDefault: async (arg) => {
    const paramas = arg;
    const response = await axios.post(`${BASE_DEV}/qsch/claims/`, paramas, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  },

  fetchDataFilter: async () => {
    const response = await axios.get(`${BASE_DEV}/qsch/filters/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    const data = response.data;
    return data;
  },

  fetchDesgrabador: async (video_url, lang) => {
    const response = await axios.get(
      `${BASE_DEV}/desgrabador/api/v1/subtitles?youtube_url=${video_url}&lang=${lang}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchDesgrabadorHistorial: async () => {
    const response = await axios.get(
      `${BASE_DEV}/desgrabador/api/v1/recent-yt-subtitles/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchDesgrabadorHistorialID: async (id, lang) => {
    const response = await axios.get(
      `${BASE_DEV}/desgrabador/api/v1/recent-yt-subtitles?id=${id}&lang=${lang}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchDesgrabadorFile: async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${BASE_DEV}/desgrabador/api/v1/transcribir/`,
      formData,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          // Accept: "application/json",
        },
      }
    );
    console.log("FICHERO**", response.data);
    const data = response.data;

    // TODO: validar con Back formato en el que tienen que venir los subtitulos,
    // sobre todo si podemos traer timestamps
    return {
      subtitles: [
        {
          text: data.transcription,
          id: 1,
          timestamp_seconds: 0,
        },
      ],
    };
  },

  fetchDesgrabadorHistorialFichero: async () => {
    const response = await axios.get(
      `${BASE_DEV}/desgrabador/api/v1/recent-transcriptions?n=10`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchDesgrabadorHistorialFicheroID: async (id, lang) => {
    const response = await axios.get(
      `${BASE_DEV}/desgrabador/api/v1/recent-transcriptions/?id=${id}&lang=${lang}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchDataCardStatus: async (id, ic) => {
    const response = await axios.get(
      `${BASE_DEV}/qsch/claims/validate/?id=${id}&fact_checkable=${ic}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchDataModulos: async () => {
    const response = await axios.get(`${BASE_DEV}/configuracion/modulos/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    localStorage["items"] = JSON.stringify(data);
  },

  fetchTrello: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/qsch/claims/trello?id=${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchTextos: async (text) => {
    const paramas = {
      text: text,
    };
    const response = await axios.post(
      `${BASE_DEV}/textos/fact-checkable/`,
      paramas,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchTextosDesgrabador: async (sentences) => {
    const paramas = {
      sentences: sentences,
    };
    const response = await axios.post(
      `${BASE_DEV}/textos/fact-checkable/`,
      paramas,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    console.log("data api", data);
    return data;
  },
  /* 
  fetchDataMonitoreo: async (mockmonitoreo) => {
    const response = await axios.get(`${BASE_DEV}/monitoreo/all/list`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    //const formatter = fromatterJson(mockmonitoreo);
    const formatter = fromatterJson(response.data);
    return formatter;
  }, */

  fetchDataUser: async () => {
    const response = await axios.get(
      `${BASE_DEV}/configuracion/user-profile/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    return data;
  },

  fetchDataResetPassword: async (pass) => {
    const paramas = {
      pass,
    };
    const response = await axios.put(
      `${BASE_DEV}/configuracion/user/change-password`,
      paramas.pass,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data.token;
    return data;
  },

  fetchNewDataUser: async (id, data) => {
    const paramas = {
      data,
    };
    const response = await axios.put(
      `${BASE_DEV}/configuracion/user-profile/${id}/`,
      paramas.data,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const res = response.data;
    return res;
  },

  fetchListData: async () => {
    const response = await axios.get(
      `${BASE_DEV}/configuracion/users/`,

      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const res = response.data;
    return res;
  },

  fetchListInvitation: async (newMedio) => {
    const paramas = {
      newMedio,
    };
    const response = await axios.post(
      `${BASE_DEV}/configuracion/users/`,
      paramas.newMedio,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );

    /* const res = response;
      if (res == 200) {
            return res;
          } else {
            return "error";
          }
    */
  },

  fetchListInvitationDelete: async (id) => {
    const response = await axios.delete(
      `${BASE_DEV}/configuracion/users/${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return true;
    }
  },

  fetchMediosUserQsch: async () => {
    const response = await axios.get(
      `${BASE_DEV}/configuracion/setting-qsch/`,

      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const res = response.data;
    if (response.status == 200) {
      return res;
    } else if (response.status == 206) {
      return null;
    }
  },

  fetchNewUserActive: async (id, data) => {
    const paramas = {
      data,
    };
    const response = await axios.patch(
      `${BASE_DEV}/configuracion/users/${id}`,
      paramas.data,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const res = response.data.success;
    if (res) {
      return res;
    } else {
      return "error";
    }
  },

  fetchCodeNewUser: async (data) => {
    const paramas = {
      data,
    };
    const response = await axios.post(
      `${BASE_DEV}/configuracion/user/invitation-confirm/`,
      paramas.data
      /* {
         headers: {
           Authorization: "Token " + localStorage.getItem("token"),
           "Content-Type": "application/json",
         },
       } */
    );
    const res = response;
    if (res.data.success == true) {
      return res.data;
    } else {
      return "error";
    }
  },

  fetchFirstPass: async (data) => {
    const paramas = {
      data,
    };
    const response = await axios.post(
      `${BASE_DEV}/configuracion/accounts/create-password/`,
      paramas.data,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const res = response;
  },

  fetchResetPass: async (data) => {
    const paramas = {
      data,
    };
    const response = await axios.post(
      `${BASE_DEV}/configuracion/accounts/password-reset/`,
      paramas.data
    );
    const res = response;
    return res;
  },

  fetchResetPasswordComplete: async (data) => {
    const paramas = {
      data,
    };
    const response = await axios.patch(
      `${BASE_DEV}/configuracion/accounts/password-reset-complete/`,
      paramas.data
    );
    const res = response.data.success;
    return res;
  },

  getFileUpload: async (arg) => {
    const paramas = arg;
    const response = await axios.post(
      `${BASE_DEV}/configuracion/upload`,
      paramas,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchRedes: async () => {
    const response = await axios.get(
      `${BASE_DEV}/configuracion/setting-qsch/`,

      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return response.data;
    }
  },

  fetchDataUserMonitoreo: async () => {
    const response = await axios.get(
      `${BASE_DEV}/configuracion/setting-monitoreo/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response;
    if (data.status !== 206) {
      return data.data;
    } else {
      return [
        {
          buzzsumo_rss_feed: "",
          youtube_api_key: "",
          youtube_playlist_id: "",
          crowdtangle_api_key: "",
          twitter_consumer_key: "",
          twitter_consumer_secret: "",
          twitter_access_token: "",
          twitter_access_token_secret: "",
          twitter_country_woeid: "",
          twitter_list_id: "",
        },
      ];
    }
  },

  postDataUserMonitoreo: async (arg) => {
    const paramas = arg;
    try {
      const response = await axios.post(
        `${BASE_DEV}/configuracion/setting-monitoreo/`,
        paramas,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return Object.keys(error.response.data);
    }
  },

  fetchMenuUsuarios: async () => {
    /*  const response = await axios.get(`${BASE_DEV}/configuracion/modulos/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    }); */
    const data = mockmenuUsuarios;
    localStorage["items-menu"] = JSON.stringify(data);
    return data;
  },

  fetchMonitoreoTrello: async (arg) => {
    const data = arg;
    const response = await axios.post(`${BASE_DEV}/monitoreo/trello`, data, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });

    if (response.status != 200) {
      return null;
    } else {
      return true;
    }
  },

  fetchDataMonitoreoTrendings: async () => {
    const response = await axios.get(`${BASE_DEV}/monitor/api/v1/trends`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },

  fetchDataMonitoreoEvents: async (id) => {
    const response = await axios.get(`${BASE_DEV}/monitor/api/v1/events/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },

  postMonitoreoNuevoEvento: async (nuevoEvento) => {
    const response = await axios.post(
      `${BASE_DEV}/monitor/api/v1/events/`,
      { name: nuevoEvento },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoTopics: async (id) => {
    const response = await axios.get(`${BASE_DEV}/monitor/api/v1/topics/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },

  fetchDataMonitoreo: async (filtros) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/posts/?hours_ago=${filtros.days}&is_trending=${filtros.trending}&registered=${filtros.register}&${filtros.redes}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoByID: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/posts/${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoTags: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/post-tags/?posts=${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoDesinformaciones: async (cards) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/card-tags/?card=${cards}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoTrendings: async () => {
    const response = await axios.get(`${BASE_DEV}/monitor/api/v1/trends`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },

  fetchDataMonitoreoCards: async () => {
    const response = await axios.get(`${BASE_DEV}/monitor/api/v1/cards`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return response.data;
  },

  fetchDataMonitoreoDesinformacionesDetalle: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/cards/?posts=${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  /*   fetchDataMonitoreoCategoriasForm: async () => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/card-tags/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }, */

  fetchDataMonitoreoSearch: async (query) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/cards/?search=${query}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoSearchPpal: async (query) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/posts/?search=${query}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchMonitoreoGuardadosSearch: async (query) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/cards/?registered=true&search=${query}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchDataMonitoreoSearchCategories: async (query) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/card-tags?search=${query}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchMonitoreoNuevoRegistro: async (newRegistro) => {
    const response = await axios.post(
      `${BASE_DEV}/monitor/api/v1/cards/`,
      newRegistro,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  postMonitoreoAsociar: async (asociar, id) => {
    try {
      const manualPostResponse = await axios.patch(
        `${BASE_DEV}/monitor/api/v1/posts/${id}`,
        asociar,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      return manualPostResponse.data;
    } catch (error) {
      throw error;
    }
  },

  postMonitoreoDesasociar: async (asociar, id) => {
    try {
      const manualPostResponse = await axios.patch(
        `${BASE_DEV}/monitor/api/v1/posts/${id}/`,
        asociar,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      return manualPostResponse.data;
    } catch (error) {
      throw error;
    }
  },

  /*   fetchMonitoreoNuevaCategoria: async (nuevaCategoria) => {
    const response = await axios.post(
      `${BASE_DEV}/monitor/api/v1/card-tags/`,
      nuevaCategoria,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
  }, */

  postNuevaDesinformacionManual: async (reg) => {
    try {
      const manualPostResponse = await axios.post(
        `${BASE_DEV}/monitor/api/v1/manual-posts/`,
        reg,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  },

  postNuevaDesinformacionManualTwitter: async (reg) => {
    try {
      const manualPostResponseTwiteer = await axios.post(
        `${BASE_DEV}/monitor/api/v1/platform-data/`,
        reg,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  },

  // REFACTOR

  fetchCategoriasDetalle: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/card-tags/?cards=${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  getRegistrosPage: async (filtros) => {
    var query = `?ordering=${encodeURIComponent(filtros.ordering)}&status=${
      filtros.status
    }${
      filtros.search !== undefined
        ? `&search=${encodeURIComponent(filtros.search)}`
        : ""
    }&limit=${encodeURIComponent(filtros.limit)}&offset=${encodeURIComponent(
      filtros.page
    )}`;

    if (filtros.subtopics && filtros.subtopics.length > 0) {
      for (var subtopicIdx in filtros.subtopics) {
        query += `&subtopics=${encodeURIComponent(
          filtros.subtopics[subtopicIdx]
        )}`;
      }
    }

    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/cards/` + query,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  patchMonitoreoEditar: async (editar, id) => {
    try {
      const manualPatchResponse = await axios.patch(
        `${BASE_DEV}/monitor/api/v1/cards/${id}`,
        editar,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      return manualPatchResponse.status;
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  },

  getMonitoreoCard: async (id) => {
    try {
      const getResponse = await axios.get(
        `${BASE_DEV}/monitor/api/v1/cards/${id}`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      let data = getResponse.data;
      // Format status
      if (data.status) {
        data.status = {
          value: data.status,
          label: getStatusLabel(data.status),
        };
      }
      return data;
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  },

  fetchgetRegistroIDdata: async (params) => {
    const response = await axios.get(
      `${BASE_DEV}/monitor/api/v1/posts/?cards=${params.id}&limit=${params.limit}&offset=${params.page}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  postCardTrello: async (id) => {
    const response = await axios.post(
      `${BASE_DEV}/monitor/api/v1/cards/${id}/export_to_trello/`,
      null,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  getdeleteRegistros: async (id) => {
    const response = await axios.delete(
      `${BASE_DEV}/monitor/api/v1/cards/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
  },

  fetchgetRelevancia: async (relevance, id) => {
    const response = await axios.post(
      `${BASE_DEV}/monitor/api/v1/posts/${id}/set_relevance/`,
      relevance,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
  },

  getdeleteRegistros: async (id) => {
    const response = await axios.delete(
      `${BASE_DEV}/monitor/api/v1/cards/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
  },

  fetchDataCampanas: async () => {
    const response = await axios.get(
      `https://dev.chequeado.com/monitor/api/v1/notifications/campaigns`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;
    /*     const data = [
      {
        id: 0,
        title: "string",
        body: "string",
        image_url: "string",
        click_action: "string",
        total_of_tokens: 0,
        total_clicks: 0,
        status: "Error",
        user: {
          id: 0,
          username: "string",
        },
        app: {
          name: "string",
          domain: "string",
          id: 0,
        },
        created_at: "2024-02-22T14:32:45.319Z",
        updated_at: "2024-02-22T14:32:45.319Z",
      },
      {
        id: 0,
        title: "string",
        body: "string",
        image_url: "string",
        click_action: "string",
        total_of_tokens: 0,
        total_clicks: 0,
        status: "Sent",
        user: {
          id: 0,
          username: "string",
        },
        app: {
          name: "string",
          domain: "string",
          id: 0,
        },
        created_at: "2024-02-22T14:32:45.319Z",
        updated_at: "2024-02-22T14:32:45.319Z",
      },
      {
        id: 0,
        title: "string",
        body: "string",
        image_url: "string",
        click_action: "string",
        total_of_tokens: 0,
        total_clicks: 0,
        status: "Sending",
        user: {
          id: 0,
          username: "string",
        },
        app: {
          name: "string",
          domain: "string",
          id: 0,
        },
        created_at: "2024-02-22T14:32:45.319Z",
        updated_at: "2024-02-22T14:32:45.319Z",
      },
    ]; */
    return data;
  },

  fetchSendData: async (arg) => {
    const params = JSON.stringify(arg);
    const response = await axios.post(
      `https://dev.chequeado.com/monitor/api/v1/notifications/fcm/send_notification_to_topic`,
      params,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const res = response.status;
    return res;

    /* if (res == 200) {
            return res;
          } else {
            return "error";
          } */
  },

  fetchVerificaciones: async (params) => {
    console.log("params", params);
    const response = await axios.get(
      `${BASE_DEV}/chequeador/api/v1/verifications/?limit=${params.limit}&offset=${params.page}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return response.data;
    }
  },

  fetchgetVerificacionesIDdata: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/chequeador/api/v1/verifications/${id}`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return response.data;
    }
  },

  postVerificacionNew: async (name) => {
    console.log("NAME", name);
    const response = await axios.post(
      `${BASE_DEV}/chequeador/api/v1/verifications/`,
      name,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status != 201) {
      return null;
    } else {
      return response.data;
    }
  },

  postVerificacionNewSave: async (id, items) => {
    const response = await axios.post(
      `${BASE_DEV}/chequeador/api/v1/verifications/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return response.data;
    }

    return response;
  },

  putEditVerificacionId: async (id, items) => {
    const params = {
      id, // Puedes usar un valor fijo o generar uno dinámicamente si prefieres
      ...items,
    };
    console.log("item***************", items);
    const response = await axios.put(
      `${BASE_DEV}/chequeador/api/v1/verifications/${id}/`,
      params,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return response.data;
    }
  },

  putVerificaionesNueva: async (id, items) => {
    const params = {
      id, // Puedes usar un valor fijo o generar uno dinámicamente si prefieres
      ...items,
    };
    const response = await axios.put(
      `${BASE_DEV}/chequeador/api/v1/verifications/${id}/`,
      params,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return response.data;
    }
  },

  fecthVerificaionesRating: async () => {
    const response = await axios.get(
      `${BASE_DEV}/chequeador/api/v1/rating-options/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

  fetchChequeadorBorrador: async () => {
    /*  const response = await axios.get(`${BASE_DEV}/rating`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    }); */
    const data = "http://wwww.chequeado.com";
    return data;
  },

  fetchHtmlChequeadorBorrador: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/chequeador/api/v1/draft-verification/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  fetchHtmlChequeadorBorradorNuevo: async (id) => {
    const response = await axios.get(
      `${BASE_DEV}/chequeador/api/v1/draft-verification/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    return data;
  },

  getdeleteVerificaciones: async (id) => {
    const response = await axios.delete(
      `${BASE_DEV}/chequeador/api/v1/verifications/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status != 200) {
      return null;
    } else {
      return true;
    }
  },
};


export default api;