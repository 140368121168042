const stripHtmlPreserveLinks = (html) => {
    // Create a temporary DOM element
    const temp = document.createElement('div');
    temp.innerHTML = html;
  
    // Function to process nodes recursively
    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }
  
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName.toLowerCase() === 'a') {
          const href = node.getAttribute('href');
          const text = node.textContent;
          return `${text} (${href})`;
        }
  
        return Array.from(node.childNodes)
          .map(processNode)
          .join('');
      }
  
      return '';
    };
  
    // Process the entire content
    const strippedContent = processNode(temp);
  
    return strippedContent;
  };
  
  export default stripHtmlPreserveLinks;