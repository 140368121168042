import styled from "styled-components";
import Container from "react-bootstrap/Container";

export const StyledSidePanelChequeador = styled.div`
  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #354052;
  }

  p.mandatory-indicator {
    font-size: 12px;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .close-icon {
    cursor: pointer;
    float: right;
    font-size: 20px;
  }
  .text-container {
    margin-bottom: 10px;
    font-size: 14px !important;
    width: 100%;
    margin-top: 10px;
    border-radius: 3px;
    border: 1px solid #fafafa;
    background: #fafafa;
  }
  .text-container::placeholder {
    color: #999;
  }
  .text-container {
    text-indent: 10px; /* Esto afecta tanto al placeholder como al texto ingresado */
  }
  .text-container {
    padding-left: 10px; /* Ajusta este valor según necesites */
  }
`;

export const StyledSidePanelBackgroundChequeador = styled(Container)`
  background: white;
  padding: 25px;
  border-radius: 15px;
  border: 1px solid #efefef;
  overflow-y: auto;
  overflow-x: hidden;
  width: 95%;
`;

export const StyledFixedSidePanelBackgroundChequeador = styled.div`
  background: white;
  padding: 25px;
  border-radius: 15px;
  border: 1px solid #efefef;
  width: 95%;
`;

export const StyledContainerCard = styled(Container)`
  /*   background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 10px; */
  .ellipsis-expand {
    position: relative;
    max-height: 60px; /* Altura máxima antes de aplicar ellipsis */
    overflow: hidden;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    height: auto !important;
    aspect-ratio: 16/9;
  }

  .text {
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .expand-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: #007bff; /* Color del botón "expandir" */
    cursor: pointer;
  }

  .expand-btn:focus {
    outline: none;
  }
  .dates {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    .fecha {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      margin-right: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
    .hora {
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .id {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      flex-grow: 1;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .usuario {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .dia {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
  }

  .texto {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #354052;
    cursor: pointer;
    margin-top: 14px;
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .expanded {
    -webkit-line-clamp: unset;
  }
  .redes {
    margin-top: 15px;
    font-size: 11px;
    line-height: 13px;
    color: #354052;
    .contenedor-redes {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      span {
        display: flex;
        align-items: flex-end;
      }
      .fb {
        fill: rgb(60, 90, 153);
        font-size: 22pxpx;
      }
      svg {
        color: #c4c4c4 !important;
        font-size: 15px;
        margin-right: 2px;
        margin-left: 5px;
      }
      .trendig {
        color: white;
        padding: 2px 8px;
        background: #f72d54;
        border-radius: 3px;
        margin-left: 15px;
        svg {
          margin: 0px;
          fill: white;
          padding-right: 1px;
        }
      }
      .tag {
        padding: 2px 18px;
        margin-left: 11px;
        font-size: 10px;
        line-height: 11px;
        color: #354052;
        background: #fafafa;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
      }
      .mas-info {
        font-size: 10px;
        line-height: 11px;
        text-decoration-line: underline;
        margin-right: 16px;
        color: rgba(53, 64, 82, 0.6);
        cursor: pointer;
      }
      .ver-reg {
        a {
          font-size: 10px;
          line-height: 11px;
          margin-right: 16px;
          text-decoration-line: underline;
          color: rgba(53, 64, 82, 0.6);
          &:hover {
            text-decoration-line: underline;
            color: rgba(53, 64, 82, 0.6);
          }
        }
      }
      .btn-no-reg {
        background: #354052;
        border-radius: 17.5px;
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
      }
      .btn-reg {
        background: #73bd82;
        border-radius: 17px 1px 1px 17px;
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 6px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        svg {
          fill: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-left: 0;
          margin-right: 5px;
        }
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .links {
    font-size: 12px;
    line-height: 11px;
    text-decoration-line: underline;
    color: rgba(53, 64, 82, 0.4);
    display: flex;
    justify-content: left;
    cursor: pointer;
    p {
      margin-bottom: 0;
      margin-left: 10px;
      &:first-child {
        margin-left: 0px;
      }
      &:hover {
        color: red;
      }

      svg {
        font-size: 10px;
        margin-right: 2px;
      }
      a {
        color: rgba(53, 64, 82, 0.4);
        text-decoration: none;
        &:hover {
          color: red;
        }
      }
    }
  }
  .footer-card {
    margin-top: 20px;
    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      font-weight: bold;
    }
    hr {
      margin: 4px 0;
      margin-bottom: 13px;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        border: 0.5px solid #c4c4c4;
        border-radius: 4px;
        font-size: 12px;
        line-height: 9px;
        color: #364151;
        padding: 4px;
        margin-bottom: 4px;
      }
    }
  }
`;

export const StyledForm = styled.div`
  .boton-enviar {
    background: #354052;
    border-radius: 17.5px;
    color: white;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #ffffff;
    padding: 5px 27px;
    font-weight: bold;
    border: 1px solid #354052;
  }
`;
